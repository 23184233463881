import './Footer.css';

function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <div className="footer-wrapper">
            <div className="footer-left">
                <span>© 2023 - {currentYear} Mimdinare</span>
            </div>
            <div className="footer-right">
                <div className="footer-developed-by">
                    <span>Crafted By</span>
                    <a href="https://artisans.ge/" target="_blank" rel="noreferrer">
                        Artisans
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
